<template>
  <div id="baroptions">
    <el-form-item
      v-if="chartConfig.xaxis.type !== 'datetime'"
      label="Horizontal"
    >
      <el-switch
        @change="updateChart"
        inactive-color="#24272b"
        v-model="plotOptions.bar.horizontal"
      ></el-switch>
    </el-form-item>

    <el-form-item label="Distributed Bars">
      <el-switch
        @change="updateChart"
        inactive-color="#24272b"
        v-model="plotOptions.bar.distributed"
      ></el-switch>
    </el-form-item>

    <h4>Border Radius</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="plotOptions.bar.borderRadius"
        :max="50"
        :min="0"
        show-input
      ></el-slider>
    </div>

    <el-form-item class="mt2" label="Border Radius Application">
      <el-radio-group
        @change="updateChart"
        size="small"
        v-model="plotOptions.bar.borderRadiusApplication"
      >
        <el-radio-button label="around">Around</el-radio-button>
        <el-radio-button label="end">End</el-radio-button>
      </el-radio-group>
    </el-form-item>

    <el-form-item class="mt2" label="Border Radius When Stacked">
      <el-radio-group
        @change="updateChart"
        size="small"
        v-model="plotOptions.bar.borderRadiusWhenStacked"
      >
        <el-radio-button label="all">All</el-radio-button>
        <el-radio-button label="last">Last</el-radio-button>
      </el-radio-group>
    </el-form-item>

    <el-form-item class="mt2" label="Labels Position">
      <el-radio-group
        @change="updateChart"
        size="small"
        v-model="plotOptions.bar.dataLabels.position"
      >
        <el-radio-button label="top">Top</el-radio-button>
        <el-radio-button label="center">Center</el-radio-button>
        <el-radio-button label="bottom">Bottom</el-radio-button>
      </el-radio-group>
    </el-form-item>

    <div v-if="!plotOptions.bar.horizontal">
      <h4>Column Width</h4>
      <div class="block">
        <el-slider
          @change="updateChart"
          v-model="plotOptions.bar.columnWidth"
          :max="150"
          :min="0"
          show-input
        ></el-slider>
      </div>
    </div>

    <div v-if="plotOptions.bar.horizontal">
      <h4>Bar Height</h4>
      <div class="block">
        <el-slider
          @change="updateChart"
          v-model="plotOptions.bar.barHeight"
          :max="100"
          :min="0"
          show-input
        ></el-slider>
      </div>
    </div>

    <el-form-item
      class="custom-colors-picker mt2"
      label="Background Bar Colors"
    >
      <extended-el-color-picker
        v-for="(color, index) in plotOptions.bar.colors.backgroundBarColors"
        :key="index"
        @change="
          (val) => {
            updateBackgroundBarColors(val, index);
          }
        "
        :value="color"
      ></extended-el-color-picker>
      <el-button @click="appendBackgroundBarColor" size="mini">
        <i class="el-icon-plus"></i>
      </el-button>
      <el-button
        @click="clearAllBackgroundBarColors"
        size="mini"
        title="Delete All Colors"
      >
        <i class="el-icon-delete"></i>
      </el-button>
    </el-form-item>

    <h4>Bar Background Opacity</h4>
    <div class="block">
      <el-slider
        @change="updateChart"
        v-model="plotOptions.bar.colors.backgroundBarOpacity"
        :max="1"
        :min="0"
        :step="0.1"
        show-input
      ></el-slider>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: 70,
          barHeight: 70,
          distributed: false,
          borderRadius: 10,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          colors: {
            ranges: [],
            backgroundBarColors: [],
            backgroundBarOpacity: 1,
          },
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
    };
  },
  watch: {
    chartConfig: function(newProp) {
      const p = newProp.plotOptions.bar;

      this.setNewOptions(p);
    },
  },
  mounted: function() {
    const p = this.getChartConfig().w.config.plotOptions.bar;
    this.setNewOptions(p);
  },
  methods: {
    setNewOptions(p) {
      this.plotOptions = {
        bar: {
          horizontal: p.horizontal,
          columnWidth: parseInt(p.columnWidth),
          barHeight: parseInt(p.barHeight),
          distributed: p.distributed,
          borderRadius: p.borderRadius,
          borderRadiusApplication: p.borderRadiusApplication,
          borderRadiusWhenStacked: p.borderRadiusWhenStacked,
          colors: {
            ranges: p.colors.ranges,
            backgroundBarColors: p.colors.backgroundBarColors,
            backgroundBarOpacity: p.colors.backgroundBarOpacity,
          },
          dataLabels: {
            position: p.dataLabels.position,
          },
        },
      };
    },
    appendBackgroundBarColor() {
      if (!this.plotOptions.bar.colors.backgroundBarColors) {
        this.plotOptions.bar.colors.backgroundBarColors = [];
      }
      this.plotOptions.bar.colors.backgroundBarColors.push("#f1f2f3");
      this.updateChart();
    },
    updateBackgroundBarColors(color, index) {
      Vue.set(this.plotOptions.bar.colors.backgroundBarColors, index, color);
      this.updateChart();
    },
    clearAllBackgroundBarColors: function() {
      this.plotOptions.bar.colors.backgroundBarColors = undefined;
      this.updateChart();
    },
    updateChart: function() {
      const p = this.plotOptions;
      this.updateChartOptions({
        plotOptions: {
          bar: {
            horizontal: p.bar.horizontal,
            columnWidth: p.bar.columnWidth.toString() + "%",
            barHeight: p.bar.barHeight.toString() + "%",
            distributed: p.bar.distributed,
            borderRadius: p.bar.borderRadius,
            borderRadiusApplication: p.bar.borderRadiusApplication,
            borderRadiusWhenStacked: p.bar.borderRadiusWhenStacked,
            colors: {
              ranges: p.bar.colors.ranges,
              backgroundBarColors: p.bar.colors.backgroundBarColors,
              backgroundBarOpacity: p.bar.colors.backgroundBarOpacity,
            },
            dataLabels: {
              position: p.bar.dataLabels.position,
            },
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
